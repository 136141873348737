import { useState, useEffect } from 'react'
import isEmail from 'validator/lib/isEmail'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import PaymentHelp from '../popups/PaymentHelp'
import PaymentCard from './PaymentCard'
import PaymentCardCrypto from './PaymentCardCrypto'
import '../../styles/paymentPicker.css'
import { didCustomerOrder } from '../order/didCustomerOrder'

function isItWeekend() {
    const now = new Date()
    const day = now.getDay()
    const hour = now.getHours()

    if (
        (day === 5 && hour >= 18) ||
        day === 6 ||
        day === 0
    ) {
        return true
    } else {
        return false
    }
}

const PaymentPicker = (props) => {
    const { t } = useTranslation()
    const { coins, coin, setCoin, coinPrice, total, txId, setTxId, setCoinPrice, orderId, email, saveOrder, setError, error, dvd = false } = props

    const [pickedCard, setPickedCard] = useState('none')
    const [prevCustomer, setPrevCustomer] = useState(false)

    useEffect(() => {
        const checkEmail = async () => await didCustomerOrder(email, setPrevCustomer)
        isEmail(email) && checkEmail()
    }, [email])

    const cashappData = coins.find(i => i.value === 'CASHAPP')
    const zelleData = coins.find(i => i.value === 'ZELLE')
    const byMailData = coins.find(i => i.name === 'By Mail')
    let cryptoData = coins.filter(i => i.value !== 'CASHAPP' &&
        i.value !== 'ZELLE' &&
        i.name !== 'By Mail' &&
        i.name !== 'gumroad' &&
        i.name !== 'phonepe' &&
        i.name !== 'coinz')
    let gumroadData = coins.find(i => i.value === 'gumroad')
    const coinzData = coins.find(i => i.value === 'coinz')

    const handleSetCoin = (c) => {
        switch (c) {
            case 'CASHAPP':
                setCoin(cashappData)
                break
            case 'ZELLE':
                setCoin(zelleData)
                break
            case 'USD':
                setCoin(byMailData)
                break
            case 'gumroad':
                setCoin(gumroadData)
                break
            case 'coinz':
                setCoin(coinzData)
                break
            default:
                setCoin('none')
        }
    }

    const handleOnChange = (e) => {
        setError('')
        const coin = e.target.value
        setPickedCard(coin)
        handleSetCoin(coin)
    }

    return (
        <div className={`pp_main`} id="payment">
            <h2 style={styles.heading} className={`altcoinText`}>
                <span style={styles.number}>2</span>   {t('payment.choose')}
            </h2>

            <div style={styles.selectDiv}>
                <select
                    name='paymentOptions'
                    style={error === t('errors.choose_payment')
                        ? styles.error
                        : styles.options}
                    onChange={handleOnChange}
                >
                    <option key='0' value={null}>{t('main.select')}</option>


                    {(prevCustomer || isItWeekend()) &&
                        <option
                            key='coinz'
                            value='coinz'
                            style={styles.coinz}
                        >{t('coinz.new_option')} PowerCoinz
                        </option>
                    }


                    {(prevCustomer || isItWeekend()) &&
                        <option key='gumroad' value='gumroad'>
                            Amazon Pay, {t('checkout.cc')}, WeChat Pay, or AliPay
                        </option>
                    }

                    <option key='crypto' value='crypto'>{t('checkout.crypto')}</option>

                    <option key='cashapp' value='CASHAPP'>Cash App</option>

                    <option key='zelle' value='ZELLE'>Zelle</option>


                    <option key='usd' value='USD'>{t('help.mail')}</option>
                </select>
            </div>

            {/* CASH APP */}
            {pickedCard === 'CASHAPP' &&
                <PaymentCard
                    id='CASHAPP'
                    coin={coin}
                    image='https://data.powershotz.com/cashapp.png'
                    coinPrice={coinPrice}
                    txId={txId}
                    setTxId={setTxId}
                    setCoinPrice={setCoinPrice}
                    orderId={orderId}
                    total={total}
                    email={email}
                    saveOrder={saveOrder}
                    setError={setError}
                    error={error}
                    dvd={dvd}
                />
            }

            {/* ZELLE */}
            {pickedCard === 'ZELLE' &&
                <PaymentCard
                    id='ZELLE'
                    coin={coin}
                    image={zelleData.image}
                    coinPrice={coinPrice}
                    txId={txId}
                    setTxId={setTxId}
                    setCoinPrice={setCoinPrice}
                    orderId={orderId} total={total}
                    email={email}
                    saveOrder={saveOrder}
                    setError={setError}
                    error={error}
                />
            }

            {/* COINZ */}
            {pickedCard === 'coinz' &&
                (prevCustomer || isItWeekend()) &&
                <PaymentCard
                    id='coinz'
                    coin={coin}
                    setCoin={setCoin}
                    image={coinzData.image}
                    coinPrice={coinPrice}
                    txId={txId}
                    setTxId={setTxId}
                    setCoinPrice={setCoinPrice}
                    orderId={orderId} total={total}
                    email={email}
                    saveOrder={saveOrder}
                    setError={setError}
                    error={error}
                />
            }

            {/* BY MAIL */}
            {pickedCard === 'USD' &&
                <PaymentCard
                    id='USD'
                    coin={coin}
                    image={byMailData.image}
                    coinPrice={coinPrice}
                    txId={txId}
                    setTxId={setTxId}
                    setCoinPrice={setCoinPrice}
                    orderId={orderId} total={total}
                    email={email}
                    saveOrder={saveOrder}
                    setError={setError}
                    error={error}
                />
            }

            {/* GUMROAD */}
            {pickedCard === 'gumroad' &&
                (prevCustomer || isItWeekend()) &&
                <PaymentCard
                    id='gumroad'
                    coin={coin}
                    image='https://data.powershotz.com/cc.png'
                    coinPrice={coinPrice}
                    txId={txId}
                    setTxId={setTxId}
                    setCoinPrice={setCoinPrice}
                    orderId={orderId} total={total}
                    email={email}
                    saveOrder={saveOrder}
                    setError={setError}
                    error={error}
                />
            }

            {/* CRYPTO */}
            {pickedCard === 'crypto' &&
                <PaymentCardCrypto
                    coin={coin}
                    setCoin={setCoin}
                    coinList={cryptoData}
                    image="https://data.powershotz.com/crypto.png"
                    coinPrice={coinPrice}
                    txId={txId}
                    setTxId={setTxId}
                    setCoinPrice={setCoinPrice}
                    orderId={orderId}
                    total={total}
                    email={email}
                    saveOrder={saveOrder}
                    setError={setError}
                    error={error}
                    dvd={dvd}
                />
            }

            <PaymentHelp />
        </div >
    )
}

const styles = {
    number: {
        marginRight: 15,
    },
    heading: {
        fontSize: isMobile ? '1.5rem' : '1.8rem',
        marginTop: 0,
    },
    options: {
        height: 38,
        width: '100%',
        marginTop: 20,
        borderRadius: 5,
        fontSize: '1.1rem',
        paddingLeft: 8,
        backgroundColor: 'white',
    },
    error: {
        height: 38,
        width: '100%',
        marginTop: 20,
        border: '5px solid red',
        borderRadius: 5,
        fontSize: '1.1rem',
        paddingLeft: 8,
    },
    emailMe: {
        textAlign: 'center',
        marginBottom: 0,
        fontSize: '0.9rem'
    },
    coinz: {
        color: 'blue',
        fontWeight: 'bold',
        fontSize: '1.2rem'
    },
}

export default PaymentPicker
