export const generateOrderNumber = () => {
    const date = Date.now().toString()
    const length = 6
    const charset = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'
    let postfix = ''
    let prefix = ''

    const randomChar = () => charset[Math.floor(Math.random() * charset.length)]

    for (let i = 0; i < length; ++i) {
        postfix += randomChar()
        prefix += randomChar()
    }

    const randomInsert = Math.random() > 0.5 ? prefix : postfix
    const insertIndex = Math.floor(Math.random() * (date.length + 1))
    const referenceNumber = `${date.slice(0, insertIndex)}${randomInsert}${date.slice(insertIndex)}`

    return referenceNumber
}