import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HiCursorClick } from "react-icons/hi"
import '../../styles/paymentHelp.css'

const PaymentHelpContent = (props) => {
    const { t, i18n } = useTranslation()
    return (
        <div onClick={() => props.setShowModal(false)}>
            <div className={`ph_section`}>
                <h2 className={`ph_heading`}>{t('help.how_to')}</h2>

                <p>{t('help.form')}</p>

                <ol>
                    <li>{t('help.li_1')}</li>
                    <li>{t('help.li_2')}</li>
                    <li>{t('help.li_3')}</li>
                    <li>{t('help.li_4')}</li>
                </ol>

                <h2 className={`ph_emphasis`}>{t('help.done')}</h2>

                <p>{t('help.confirm')}</p>

                <p>{t('help.min')}</p>

                <ul>
                    <li>{t('help.li2_1')}</li>
                    <li>{t('help.li2_2')}</li>
                    <li><Link to="/contact">{t('nav.contact_us')}</Link></li>
                </ul>
            </div>

            <div className={`ph_section`}>
                <h2 className={`ph_heading`}>{t('help.apps')}</h2>

                <p
                    dangerouslySetInnerHTML={{
                        __html: t('help.install')
                    }}
                />

                <li style={styles.li}>{t('help.zelle')}</li>
                <li style={styles.li}>{t('help.cashapp')}</li>

                <small>Here is an <a
                    href="https://cash.app/app/JVTVKNW"
                    target="_blank"
                    rel="noreferrer"
                >invitation to set up a Cash App account</a> from Sandra (Alexandra❤️).</small>
            </div>

            <div className={`ph_section`}>
                <h2 className={`ph_heading`}>{t('help.crypto')}</h2>

                <a
                    href='https://powershopz.com/crypto_unleashed.pdf'
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button
                        className='infoButton'
                        style={{ fontSize: '1rem', marginTop: 8 }}
                    >{t('payment.buy_crypto_link')} <HiCursorClick style={{ transform: 'translateY(4px)', marginLeft: 8 }} />
                    </button>
                </a>

                <h3>{t('help.easily')}</h3>

                <p>Try{' '}
                    <a
                        href="https://metamask.io/"
                        target="_blank"
                        rel="noreferrer"
                    >MetaMask</a>,{' '}
                    <a
                        href="https://www.blockchain.com/"
                        target="_blank"
                        rel="noreferrer"
                    >Blockchain</a>,{' '}
                    <a
                        href="https://bitpay.com/"
                        target="_blank"
                        rel="noreferrer"
                    >Bitpay</a>, or{' '}
                    <a
                        href="https://azte.co/"
                        target="_blank"
                        rel="noreferrer"
                    >Azteco</a>*
                </p>

                <small>{t('help.no_recommend')}</small>

                <h3>{t('help.accept')}</h3>

                {i18n.language === 'en'
                    ? <p>If you don&apos;t see your coin, <Link to='/contact'>contact us</Link> and we&apos;ll try to accommodate you.</p>
                    : <p>{t('help.accommodate')}</p>
                }
            </div>

            <div className={`ph_section`}>
                <h2 className={`ph_heading`}>{t('help.mail')}</h2>

                <p>{t('help.mail_p1')}</p>

                <p>DesignTech<br />
                    PO Box 141<br />
                    Wildwood, MO 63038<br />
                    USA</p>

                <p>{t('help.payable')} <b style={{ fontSize: 'large' }}> DesignTech</b></p>
            </div>

            <div className={`ph_section`}>
                <h2 className={`ph_heading`}>{t('checkout.cc')}</h2>

                {i18n.language === 'en'
                    ? <p>We cannot use credit card processing at Powershotz because Pornography and other obscene materials (including literature, imagery and other media) depicting nudity or explicitly sexual acts; and adult video stores are <b>STRICTLY FORBIDDEN</b> by credit card processors, Paypal, and Venmo.</p>
                    : <p>{t('help.no_cc')}</p>
                }

            </div>

            <div className={`ph_section`}>
                <h2 className={`ph_heading`}>{t('help.other')}</h2>

                <p>{t('help.email_me')} <a href="mailto:alexandra@powershotz.com?subject=Other payment options&amp;body=Hi Alexandra,%0D%0A%0D%0AI'd like other payment options, please.%0D%0A%0D%0AYou're the best! Thanks!%0D%0A%0D%0A">alexandra@powershotz.com</a></p>
            </div>
        </div>
    )
}

const styles = {
    li: {
        margin: 10
    }
}

export default PaymentHelpContent